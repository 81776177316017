import React, { Component } from "react";
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TicketSidebar from './routes/ticketSidebar'
import CustomerSidebar from './routes/customerSidebar'


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
     <Routes>
      <Route path="/" element={<App />} />
      <Route path="ticket-sidebar" element={<TicketSidebar />} />
      <Route path="customer-sidebar" element={<CustomerSidebar />} />
    </Routes>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
