import '../App.css';
import React, { Component } from "react";

export default class CustomerSidebar extends Component {
    constructor(props) {
        super(props);
        this.state = { isButtonShow: true };
        this.getZFData = this.getZFData.bind(this)
    }

    async componentDidMount() {
        const script = document.createElement("script");

        script.src = "https://static.zdassets.com/zendesk_app_framework_sdk/2.0/zaf_sdk.min.js";
        script.async = true;

        document.body.appendChild(script);

        script.addEventListener("load", this.getZFData);
    }

    async getZFData() {
        var client = await window.ZAFClient.init()
        const data = await client.get('user')
        const externalId = data.user.externalId

        if (externalId) {
            this.setState({ isButtonShow: true, externalId: data.user.externalId.split(':') }, () => {
                console.log(this.state)
            });
        }
    }

    render() {
        return (
            <div className="App">
                <header className="App-header">
                    {/* <p>
                        Environment url :- {process.env.REACT_APP_HOST_URL}.
                    </p> */}
                    {
                        this.state.isButtonShow && this.state.externalId ? <a target="_blank" href={`${process.env.REACT_APP_HOST_URL}${this.state.externalId[0]}/${this.state.externalId[1]}/customers/${this.state.externalId[2]}`}><button>Connect Zero Friction</button></a> : ''
                    }
                </header>
            </div>
        );
    }
}
